// import { hideLoader } from '../../components/loader/loader';

window.addEventListener('load', (() => {
  window.scrollTo(0, 0);
}));

// ===== інший синтаксис ================================================================================
// window.onload = hideLoader();


// refs.body.scrollTop();
// refs.body.scrollIntoView(true);